import React from 'react';
import Button from '../components/Button';
import Card from '../components/Card';
import CustomerCard from '../components/CustomerCard';
import LabelText from '../components/LabelText';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import StatsBox from '../components/StatsBox';
import customerData from '../data/customer-data';
import HeroImage from '../svg/HeroImage';
import SvgCharts from '../svg/SvgCharts';
import MailChimpForm from "../components/MailChimpForm";
import AnchorLink from 'react-anchor-link-smooth-scroll';

export default () => (
  <Layout>
    <section className="pt-20 md:pt-40">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-4xl lg:text-5xl xl:text-6xl font-bold leading-none">
            SmartCity the Game
          </h1>
          <p className="text-xl lg:text-2xl mt-6 font-light">
            The boardgame that will change the way gamers think about building cities
          </p>
          <p className="mt-8 md:mt-12">
                        <AnchorLink  href="#signup">
                            <Button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                Kickstarter Campaign</Button>
                        </AnchorLink>



                        
          </p>
          <p className="mt-4 text-gray-600">Sign up now for our newsletter</p>
        </div>

        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section id="features" className="py-20 lg:pb-40 lg:pt-48">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">Main Features</h2>
        <div className="flex flex-col sm:flex-row sm:-mx-3 mt-12">
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Clean design</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">Simple gameplay</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
          <div className="flex-1 px-3">
            <Card className="mb-8">
              <p className="font-semibold text-xl">For everyone in the family</p>
              <p className="mt-4">
                An enim nullam tempor gravida donec enim ipsum blandit porta justo integer odio
                velna vitae auctor integer.
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <SplitSection
      id="theme"
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">What is a SmartCity?</h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
                  A Smart city is an urban area that uses different types applicatoins to collect data 
                  and then use insights gained from that data to manage assets, resources and services efficiently.
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Developing a Smart city
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Utlizing resources and ... 
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-3xl font-semibold leading-tight">
            Build the city you always wanted
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            Using your imagination ... 
          </p>
        </div>
      }
      secondarySlot={<SvgCharts />}
    />
    <section id="stats" className="py-20 lg:pt-32">
      <div className="container mx-auto text-center">
        <LabelText className="text-gray-600">Main stats about Smart City</LabelText>
        <div className="flex flex-col sm:flex-row mt-8 lg:px-24">
          <div className="w-full sm:w-1/3">
            <StatsBox primaryText="60-90 min" secondaryText="Playing time" />
          </div>
          <div className="w-full sm:w-1/3">
                      <StatsBox primaryText="1-4" secondaryText="Number of players" />
          </div>
          <div className="w-full sm:w-1/3">
                        <StatsBox primaryText="80x80 cm" secondaryText="Space needed" />
          </div>
        </div>
      </div>
    </section>
    <section id="aboutus" className="py-20 lg:py-40">
      <div className="container mx-auto">
                <LabelText className="mb-8 text-gray-600 text-center">
                    About us</LabelText>
                <div className="flex flex-col md:flex-row md:-mx-3">
                    {customerData.map(customer => (
                    <div key={customer.customerName} className="flex-1 px-3">
                    <CustomerCard customer={customer} />
                    </div>
                ))}
                </div>
      </div>
    </section>
        <section id="signup" className="container mx-auto my-24 py-24 bg-gray-200 rounded-lg text-center">
            <h3 className="text-5xl font-semibold">
                Want to know more about the game?
            </h3>
            <p className="mt-8 text-xl font-light">
                Sign up for the newsletter now and we will send you updates on the ongoing development of the game.
            </p> 
            <div style={{ maxWidth: `1080px`,margin: `0 auto !important`,float: `none !important`, marginBottom: `1.45rem` }}> 
                <MailChimpForm />
            </div>
       </section>
        
  </Layout> 
);
