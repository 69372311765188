import React from 'react';

const Footer = () => (
  <footer className="container mx-auto py-16 px-3 mt-48 mb-0 text-gray-800">
    <div className="flex -mx-3">
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">About Us</h2>
                <p className="mt-5">
                    We focus on developing a boardgame that will inspire people to think about 
                     
                    
                    </p>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Important Links</h2>
        <ul className="mt-4 leading-loose">
          <li>
            <a href="https://smartcitygame.com">Terms &amp; Conditions</a>
          </li>
          <li>
            <a href="https://smartcitygame.com">Privacy Policy</a>
          </li>
        </ul>
      </div>
      <div className="flex-1 px-3">
        <h2 className="text-lg font-semibold">Kickstarter Campaign</h2>
        <ul className="mt-4 leading-loose">
          <li>
            
          </li>
          <li>
            
          </li>
          <li>

          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
