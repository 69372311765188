import addToMailchimp from "gatsby-plugin-mailchimp"
import TextField from "@material-ui/core/TextField"
import Button from "@material-ui/core/Button"
import { Typography } from "@material-ui/core"
import React from "react"


class MailChimpForm extends React.Component {
  constructor() {
    super()
    this.state = { email: "", result: {} }
  }

  _handleSubmit = async e => {
    console.log("handle sub")
    e.preventDefault()
    const result = await addToMailchimp(this.state.email)
    console.log("result", result)
    this.setState({ result: result.result })
  }

  handleChange = event => {
    this.setState({ email: event.target.value })
  }
  render() {
    return this.state.result === "success" ? (
      <div>Thank you for subscribing!</div>
    ) : this.state.result === "error" ? (
      <div>You are already signed up, we will send you some news soon</div>
    ) : (
    
                <div class="row">
                    <div class="col-lg-6">
                        <div class="form-group">
  <form
      onSubmit={this._handleSubmit} 
      >
      <TextField                       
            id="outlined-email-input"
                            variant="outlined"
                            
            label="Email"
            type="email"
            name="email"                       
            autoComplete="email"
            onChange={this.handleChange}
            />
                        <Button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-5 rounded"
                                    label="Submit"
                                    type="submit"
                        >
                        <Typography variant="button">
                        Signup
                        </Typography>
              </Button>
        </form>
                        </div>
                    </div>
                </div>
    )
  }
}

export default MailChimpForm