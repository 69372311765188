export default [
  {
    title: 'Overseeing all activites of the development',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Mafalda',
    customerTitle: 'CEO',
    customerImage: 'https://placeimg.com/150/150/animals'
  },
  {
    title: 'Always ready with a creative input',
    content:
      'Eu lobortis elementum nibh tellus molestie nunc non blandit massa. Sit amet consectetur adipiscing elit duis.',
    customerName: 'Maya',
    customerTitle: 'Director of Research and Data',
    customerImage: 'https://placeimg.com/150/150/animals'
  }
];
