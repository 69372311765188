import React from 'react';

const LogoIcon = () => (
    < svg id = "Layer_1" enableBackground="new 0 0 48 48" height="40" viewBox="0 0 40 40" width = "40" xmlns = "http://www.w3.org/2000/svg" > <path d="m61.3 15.8 1.3-1.5c-5-4.3-12.4-4.3-17.3 0l1.3 1.5c4.3-3.7 10.5-3.7 14.7 0z" /><path d="m59.7 17.3c-3.2-3-8.2-3-11.4 0l1.4 1.4c2.4-2.3 6.2-2.3 8.6 0z" /><path d="m50.4 20.2 1.3 1.6c1.4-1 3.3-1 4.7 0l1.3-1.6c-2.2-1.6-5.2-1.6-7.3 0z" /><path d="m60 29c0-.6-.4-1-1-1h-4v-5h-2v5h-18c-.6 0-1 .4-1 1v31h-2v-31c0-1.7 1.3-3 3-3h2v-7h-6v2h-2v-2h-6c-2.9 0-5.4-2.1-5.9-5h-7.1c-.6 0-1 .4-1 1v17.4c1.8.8 3 2.6 3 4.6v14c0 1.6-.8 3.1-2 4v5h-2v-6.2c1.2-.4 2-1.5 2-2.8v-14c0-1.7-1.3-3-3-3s-3 1.3-3 3v14c0 1.3.8 2.4 2 2.8v6.2h-4v2h60v-2h-2zm-39 5v5h-5v-5zm0 9v5h-5v-5zm-9-19h7v6h-7zm16 36h-12v-7h12zm0-12h-5v-5h5zm0-9h-5v-5h5zm0-9h-7v-6h7zm13 27h-4v-6h4zm0-10h-4v-6h4zm0-10h-4v-6h4zm8 20h-4v-6h4zm0-10h-4v-6h4zm0-10h-4v-6h4zm8 20h-4v-6h4zm0-10h-4v-6h4zm0-10h-4v-6h4z" /><path d="m23 17h6v-6h2v6h6c2.2 0 4-1.8 4-4s-1.8-4-4-4h-1.1c-.5-3.3-3.6-5.5-6.9-4.9-2.5.4-4.5 2.4-4.9 4.9h-1.1c-2.2 0-4 1.8-4 4s1.8 4 4 4z" />
</svg >
);

export default LogoIcon;



